import { default as index2ormyVkiFIMeta } from "/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/index.vue?macro=true";
import { default as _91kit_id_936ADBWIb0GRMeta } from "/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/kit/[kit_id].vue?macro=true";
import { default as indexiuiTLxkRZdMeta } from "/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/kit/index.vue?macro=true";
import { default as indexz91XcFeF5XMeta } from "/var/www/app.conexsolution.com/releases/32/pages/access-code/index.vue?macro=true";
import { default as _91access_code_93iISeW4wNXgMeta } from "/var/www/app.conexsolution.com/releases/32/pages/check-connectivity/[access_code].vue?macro=true";
import { default as indexFkuemEP1N5Meta } from "/var/www/app.conexsolution.com/releases/32/pages/check-connectivity/index.vue?macro=true";
import { default as indexPEUJ5OtQ7xMeta } from "/var/www/app.conexsolution.com/releases/32/pages/create-survey/index.vue?macro=true";
import { default as indexQpbg6Crl6OMeta } from "/var/www/app.conexsolution.com/releases/32/pages/index.vue?macro=true";
export default [
  {
    name: "access-code-access_code",
    path: "/access-code/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/index.vue")
  },
  {
    name: "access-code-access_code-kit-kit_id",
    path: "/access-code/:access_code()/kit/:kit_id()",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/kit/[kit_id].vue")
  },
  {
    name: "access-code-access_code-kit",
    path: "/access-code/:access_code()/kit",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/access-code/[access_code]/kit/index.vue")
  },
  {
    name: "access-code",
    path: "/access-code",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/access-code/index.vue")
  },
  {
    name: "check-connectivity-access_code",
    path: "/check-connectivity/:access_code()",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/check-connectivity/[access_code].vue")
  },
  {
    name: "check-connectivity",
    path: "/check-connectivity",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/check-connectivity/index.vue")
  },
  {
    name: "create-survey",
    path: "/create-survey",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/create-survey/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/app.conexsolution.com/releases/32/pages/index.vue")
  }
]